import React, {useState, useMemo } from "react";
import { SearchWidget, Button, Checkbox, ComponentLoader, StyledLink, IconsCircleBorder, Span } from "@netapp/bxp-design-system-react";
import {useDispatch, useSelector} from "react-redux";
import styles from "./../MainHeader.module.scss";
import serviceConnectorMenuStyles from "./ConnectorMenu.module.scss";
import SlidingWidget, {CaptionButton, SlidingWidgetFooter} from "./SlidingWidget"
import classNames from "classnames";
import { ArrowUpIcon, ConnectorNoCircleIcon } from "@netapp/bxp-design-system-react/icons/monochrome";
import ListMenuContent from "./ListMenuContent"
import _ from "lodash";
import ExternalLink from "components/ExternalLink";
import typography from "@netapp/bxp-design-system-react/styles/typography.module.scss"
import { providersMap } from "utils/providers";
import buttons from "@netapp/bxp-design-system-react/styles/buttons.module.scss"

const Status = ({status}) => {
    return <span className={classNames(serviceConnectorMenuStyles["item-detail"], serviceConnectorMenuStyles['item-status'])}><span className={serviceConnectorMenuStyles[`box-${status}`]}/>{status}</span>
};

const changeVmTypeString = (provider) => {
    if(provider === "aws") {
        return "Change instance required."
    } else if (provider === "gcp") {
        return "Change machine type required."
    } else if (provider === "azure") {
        return "Change VM type required."
    } else {
        return "Increase VM performance required."
    }
}

const ServiceConnectorItem = React.memo(({ index, style, data: {agents, setSelectedAgent, selectedAgent} }) => {
    const dispatch = useDispatch();
    const agent = agents[index];
    const isSelected = selectedAgent === agent;
    const {provider, region, status, primaryCallbackUri}  = agent;
    const darkSite = process.env.REACT_APP_IS_DARK === 'true';
    const useDockerInfra = agent.agent?.useDockerInfra || darkSite;
    const isLocalUi = process.env.REACT_APP_IS_DARK !== "true" && process.env.REACT_APP_IS_SAAS !== "true";

    return <div style={style} className={serviceConnectorMenuStyles['item']}>
        <Checkbox disabled={status !== "active" || process.env.REACT_APP_IS_SAAS !== "true"} className={classNames(serviceConnectorMenuStyles['checkbox'], {[serviceConnectorMenuStyles['checkbox-disabled']]: status !== "active"})} onChange={() => {
            if(isSelected) {
                return;
            }
            setSelectedAgent(agent)
        }} checked={isSelected} ariaLabel={agent.name}>
            <div className={serviceConnectorMenuStyles["top-line-info"]}>
            <span title={agent.name}>{agent.name} </span>
            {(process.env.REACT_APP_IS_SAAS === "true" || (isLocalUi && !isSelected)) && <ExternalLink className={classNames(buttons.text, serviceConnectorMenuStyles.menu,`${!primaryCallbackUri ? serviceConnectorMenuStyles['disable-pointers'] : 'none'}`)} trackCategory="Connectors" trackLabel="Go to Local UI" disabled={!primaryCallbackUri} href={primaryCallbackUri} >
                Go to Local UI <ArrowUpIcon size={16} style={{ marginLeft: 3,transform:'rotate(45deg)'}}/>
            </ExternalLink>}
            {(process.env.REACT_APP_IS_DARK === "true" || isLocalUi) && isSelected && <StyledLink
                variant="text"
                style={{ margin: "0px 5px 0px auto" }}
                to={location => ({ ...location, hash: "connectorsManagement" })}
                onClick={() => dispatch({ type: "MENU:CLOSE-WIDGET" })}
            >
                Edit Connector
            </StyledLink>}
            </div>
        </Checkbox>
        <div className={serviceConnectorMenuStyles["details-row"]}>
            <span className={serviceConnectorMenuStyles["item-detail"]}>{providersMap[provider]?.label || provider}</span>
            <span className={serviceConnectorMenuStyles["item-detail"]}>{region || "-"}</span>
            <Status status={status}/>
        </div>
        {!useDockerInfra && <div className={serviceConnectorMenuStyles['need-upgrade-notice']}>
            <span className={classNames(typography.error, typography.b)}>Notice: </span> {changeVmTypeString(provider)} <Button variant="text" onClick={() => {
            dispatch({
                type: "CURRENT-CONNECTOR:OPEN-WEAK-CONNECTOR-DIALOG",
                payload: {
                    provider
                },
                track: "from-connector-menu"
            })
        }}>Learn more</Button>
        </div>}
    </div>;
});

const ServiceConnectorMenuFooter = ({activeServiceConnector, selectedServiceConnector}) => {
    const dispatch = useDispatch();

    return <SlidingWidgetFooter onSwitch={() => {
        if(activeServiceConnector === selectedServiceConnector){
            return;
        }

        dispatch({
            type: "TENANCY:SET-AGENT",
            payload: {
                agentId: selectedServiceConnector.agentId
            },
            track: true
        });

        dispatch({
            type: "MENU:CLOSE-WIDGET"
        })
    }} onClose={() => {
        dispatch({
            type: "MENU:CLOSE-WIDGET"
        })
    }}/>
};

const noAgents = [];
const MenuContent = React.memo(({setSelectedAgent, selectedAgent, removeConnector}) => {
    // connectorChanges: Remove this comment once the agent association is approved. 
    // const agents = useSelector(state => isTenancyV4 ? state.tenancy?.selectedScope?.associatedAgents : state.tenancy.agents) || noAgents;
    const agents = useSelector(state => state.tenancy.agents) || noAgents;
    const agentStatus = useSelector(state => state.tenancy.agentStatus);
    const errorReason = useSelector(state => state.tenancy.agentErrorReason);
    const activeAgent = useSelector(state => state.tenancy.selectedAgent);
    const [activeFilter, setActiveFilter] = useState("");
    const permissions = useSelector(state => state.tenancy.permissions);

    const filteredAgents = useMemo(() => {
        if(activeFilter) {
            const lowerCasedFilter = _.toLower(activeFilter);
            return _.filter(agents, agent => {
                return _.includes(_.toLower(agent.name), lowerCasedFilter) || agent.status === lowerCasedFilter || agent.provider === lowerCasedFilter || agent.region === lowerCasedFilter;
            })
        } else {
            return agents;
        }
    }, [activeFilter, agents]);

    if(removeConnector) {
        return ""
    }

    const template = agentStatus === "SUCCESS" && agents.length > 0 ? "65px 1fr" : "1fr";

    return <div style={{display: "grid", gridTemplateRows: template, height: "100%"}}>
        {agentStatus === "PENDING" && <div>
            <ComponentLoader className={styles["menu-loader"]}/>
        </div>}
        {agentStatus === "SUCCESS" && agents.length === 0 && <div className={serviceConnectorMenuStyles['no-connector']}>
            <Span className={serviceConnectorMenuStyles['icon-position']}>
                <IconsCircleBorder>
                    <ConnectorNoCircleIcon color="disabled"/>
                </IconsCircleBorder>
            </Span>
            <span className={classNames(typography.body16, typography["text-disabled"])}>No existing connectors</span>
            {permissions.addConnector && <StyledLink large to={location => ({...location, hash: "addConnector/provider", state: {from: "connectorMenu"}})}>Create your first Connector</StyledLink>}
        </div>}
        {agentStatus === "SUCCESS" && agents.length > 0 && <>
            <SearchWidget color="secondary" className={serviceConnectorMenuStyles['search-widget-container']} alwaysOpen={true} placeholder="Search BlueXP Connectors" setFilter={setActiveFilter} widgetClassName={serviceConnectorMenuStyles['search-widget']}/>
            <ListMenuContent
                itemSize={116}
                label="connectors"
                errorReason={errorReason}
                heightOffset={290 - (process.env.REACT_APP_IS_SAAS === "true" ? 0 : 65)}
                Item={ServiceConnectorItem}
                status={agentStatus}
                activeItem={activeAgent}
                selectedItem={selectedAgent}
                setSelectedItem={setSelectedAgent}
                items={filteredAgents}
                itemData={{agents: filteredAgents, setSelectedAgent, selectedAgent, permissions}}
            />
        </>}
    </div>
});

const MenuHeaderButtons = () => {
    const permissions = useSelector(state => state.tenancy.permissions);

    return process.env.REACT_APP_IS_SAAS === "true" && <div style={{ height: 24, display: "inline-flex", alignItems: "center" }}>
        {permissions.addConnector && <StyledLink type="text" to={location => ({ ...location, hash: "addConnector/provider", state: { from: "connectorMenu" } })}>Add Connector</StyledLink>}
        <div style={{ width: permissions.addConnector ? 1 : 0, height: "100%", backgroundColor: "var(--border-main)", margin: "0 16px" }} />
        <StyledLink type="text" to={location => ({ ...location, hash: "connectorsManagement" })}>Manage Connectors</StyledLink>
    </div>
}

export default () => {
    const activeAgent = useSelector(state => state.tenancy.selectedAgent);
    // connectorChanges: Remove this comment once the agent association is approved. 
    // const agents = useSelector(state => isTenancyV4 ? state.tenancy?.selectedScope?.associatedAgents : state.tenancy.agents) || [];
    const agents = useSelector(state => state.tenancy.agents) || [];
    const agentStatus = useSelector(state => state.tenancy.agentStatus);
    const [selectedAgent, setSelectedAgent] = useState(activeAgent);
    const agentName = activeAgent?.name || "N/A";
    const isAddConnectorMenuOpen = useSelector(state => state.app.addServiceConnectorWidgetOpen);
    const removeConnector = useSelector(state => state.menu.removeConnector);

    return <SlidingWidget
        widgetKey="connectors"
        menuTitle="Connectors"
        triggerClassName={styles['caption-button']}
        triggerActiveClassName={[styles['caption-button-active']]}
        TriggerButton={props => <CaptionButton title="Connector" value={agentName} {...props}/>}
        MenuFooter={process.env.REACT_APP_IS_SAAS !== "true" ? undefined : (() => {
            if(removeConnector || (agentStatus === "SUCCESS" && agents.length === 0)) {
                return ""
            } else {
                return <ServiceConnectorMenuFooter activeServiceConnector={activeAgent} selectedServiceConnector={selectedAgent}/>
            }
        })}
        HeaderContents={MenuHeaderButtons}
        canClose={() => {
            return !isAddConnectorMenuOpen;
        }}
    >
        <MenuContent removeConnector={removeConnector} setSelectedAgent={setSelectedAgent} selectedAgent={selectedAgent}/>
    </SlidingWidget>
};
